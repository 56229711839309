import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const Image = () => {
  return (
    <StaticImage
      layout='fullWidth'
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: '50%',
        height: '100%'
      }}
      quality={95}
      src='../../../../images/zdj-7b-1024x684.jpg'
      alt=''
    />
  );
};
