import { ListItem } from '@mui/material';
import React from 'react';

interface PointListItemProps {
  text: string;
}

const MobilePointListItem: React.FC<PointListItemProps> = ({ text }) => {
  return (
    <ListItem
      sx={{
        display: 'list-item',
        paddingLeft: '3px',
        paddingBottom: 0
      }}
    >
      {text}
    </ListItem>
  );
};

export default MobilePointListItem;
