import { List } from '@mui/material';
import React from 'react';
import MobilePointListItem from './MobilePointListItem';

interface PointsListsProps {
  points: string[];
}

const MobilePointsLists: React.FC<PointsListsProps> = ({ points }) => {
  return (
    <List
      sx={{
        listStyle: 'disc',
        listStylePosition: 'inside',
        textTransform: 'none',
        fontSize: '1rem',
        paddingTop: 0
      }}
    >
      {points.map((point) => (
        <MobilePointListItem key={point} text={point} />
      ))}
    </List>
  );
};

export default MobilePointsLists;
