import { Box } from '@mui/material';
import React from 'react';

export const BenefitArrow = () => {
  return (
    <Box
      height={'100%'}
      position={'absolute'}
      borderRight={'22px solid'}
      borderColor={'secondary.main'}
      sx={{
        borderTop: '30px solid transparent',
        borderBottom: '30px solid transparent'
      }}
      right={0}
      top={0}
    />
  );
};
