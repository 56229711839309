import { Box, List } from '@mui/material';
import React from 'react';
import { DesktopBenefitItem } from './DesktopBenefitItem';
import { Benefit } from '../../Benefit';

interface BenefitsListProps {
  benefits: Benefit[];
  activeBenefitId: string;
  handleUpdateBenefit: (id: string) => void;
}
const DesktopBenefitsList: React.FC<BenefitsListProps> = ({ benefits, activeBenefitId, handleUpdateBenefit }) => {
  return (
    <Box flex={1}>
      <List
        sx={{
          listStyle: 'none',
          marginLeft: 5
        }}
      >
        {benefits.map((benefit, index) => {
          return (
            <DesktopBenefitItem
              key={benefit.id}
              isActive={benefit.id === activeBenefitId}
              onClick={() => handleUpdateBenefit(benefit.id)}
              text={benefit.title}
              index={index}
            />
          );
        })}
      </List>
    </Box>
  );
};

export default DesktopBenefitsList;
