import React from 'react';
import PageSection from '../../../components/Sections/PageSection';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { Stack, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';

export default () => {
  return (
    <PageSection bgcolor='tertiary.main' maxWidth='lg'>
      <PageSectionTitle>Rozliczenia i poziom prowizji</PageSectionTitle>
      <Typography fontWeight={600}>
        Korzyści finansowe, jakie osiągają nasi Partnerzy, składają się z dwóch obszarów. Za sprzedaż naszego systemu
        Partnerzy otrzymują prowizję w wysokości 10%. Za opiekę i prowadzenie Klienta po wdrożeniu Partnerzy otrzymują
        dodatkowe 10% prowizji. Łączna prowizja to nawet 20% od każdej wystawionej Klientowi faktury miesięcznej.
      </Typography>
      <Stack gap={4} marginTop={3}>
        <Typography variant='h5' component={'span'} fontWeight={600}>
          Możesz prowadzić swojego klienta samodzielnie lub podzielić się z nami zadaniami, jesteśmy elastyczni.
        </Typography>

        <StaticImage
          src='../../../images/rozliczenia_prowizje_diagram.png'
          alt='Rozliczenia i poziom prowizji'
          quality={100}
        />
      </Stack>
    </PageSection>
  );
};
