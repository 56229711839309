import { Box, List } from '@mui/material';
import React from 'react';
import BenefitItem from './MobileBenefitItem';
import { Benefit } from '../../Benefit';

interface BenefitsListProps {
  benefits: Benefit[];
}

const BenefitsList: React.FC<BenefitsListProps> = ({ benefits }) => {
  return (
    <Box>
      <List
        sx={{
          listStyle: 'decimal',
          listStylePosition: 'inside'
        }}
      >
        {benefits.map((benefit) => {
          return <BenefitItem benefit={benefit} key={benefit.id} />;
        })}
      </List>
    </Box>
  );
};

export default BenefitsList;
