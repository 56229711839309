import React from 'react';
import PageSection from '../../../components/Sections/PageSection';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { StaticImage } from 'gatsby-plugin-image';

export default () => {
  return (
    <>
      <PageSection bgcolor='tertiary.main' maxWidth='lg'>
        <PageSectionTitle>Proste zasady nawiązywania współpracy partnerskiej z Mediportą</PageSectionTitle>
        <StaticImage
          src='../../../images/partner_nawiazanie_wspolpracy.png'
          alt='Zasady nawiązywania współpracy'
          layout='constrained'
          quality={100}
        />
      </PageSection>
    </>
  );
};
