import { Stack } from '@mui/material';
import React from 'react';
import { Benefit } from '../Benefit';
import MobileBenefitsList from './Benefit/MobileBenefitsList';
import MobileHeader from './MobileHeader';

interface CooperationBenefitSectionMobileProps {
  benefits: Benefit[];
}

const CooperationBenefitSectionMobile: React.FC<CooperationBenefitSectionMobileProps> = ({ benefits }) => {
  return (
    <Stack paddingTop={3} paddingLeft={2}>
      <MobileHeader />
      <MobileBenefitsList benefits={benefits} />
    </Stack>
  );
};

export default CooperationBenefitSectionMobile;
