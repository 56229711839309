import React from 'react';
import StandardFrame from '../components/StandardFrame';
import { PageProps, graphql } from 'gatsby';
import CooperationBenefitsSection from '../components/Partners/CooperationBenefitSection';
import ITPartnerWhoCanBecomeSection from '../sections/zostan-partnerem-it/ITPartnerWhoCanBecomeSection';
import Header from '../sections/zostan-partnerem-it/naglowek';
import ItPartnerSettlementsAndCommisionSection from '../sections/zostan-partnerem-it/rozliczenia-poziom-prowizji';
import PageSection from '../components/Sections/PageSection';
import PageSectionTitle from '../components/Sections/PageSectionTitle';
import TestimonialCarousel from '../components/TestimonialCarousel';
import CooperationRulesSection from '../sections/zostan-partnerem-it/zasady-nawiazywania-wspolpracy';
import { ContactForm } from '../components/Sections/ContactForm';
import { Helmet } from 'react-helmet';

export const pageQuery = graphql`
  query BecomeItPartner {
    benefits: allWspolpracaZyskiYaml {
      edges {
        node {
          id
          title
          order
          points
        }
      }
    }
    partners: allKtoMozeZostacPartneremYaml {
      edges {
        node {
          id
          title
        }
      }
    }
    testimonial: allMarkdownRemark(filter: { childTestimonial: { id: { ne: null } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            name
            captions
            photo {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  width: 250
                  quality: 95
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          html
        }
      }
    }
  }
`;

export default ({ data: { partners, benefits, testimonial } }: PageProps<Queries.BecomeItPartnerQuery>) => {
  return (
    <>
      <Helmet title='Zostań partnerem IT' />
      <StandardFrame>
        <Header />

        <ITPartnerWhoCanBecomeSection
          partners={partners.edges.map((p) => ({
            id: p.node.id,
            text: p.node.title!
          }))}
        />

        <CooperationBenefitsSection
          benefits={benefits.edges.map((e) => ({
            id: e.node.id!,
            points: e.node.points as string[],
            title: e.node.title!
          }))}
        />
        <ItPartnerSettlementsAndCommisionSection />

        <PageSection bgcolor='primary.main' color='white' maxWidth='lg'>
          <PageSectionTitle>Zobacz, co mówią o Mediporcie nasi klienci</PageSectionTitle>
          <TestimonialCarousel
            items={testimonial.edges.map(({ node }) => ({
              name: node!.frontmatter!.name!,
              captions: node!.frontmatter!.captions!.map((caption) => caption!),
              photo: node!.frontmatter!.photo?.childImageSharp?.gatsbyImageData,
              html: node!.html!
            }))}
          />
        </PageSection>

        <CooperationRulesSection />

        <ContactForm bgcolor={'background.default'} />
      </StandardFrame>
    </>
  );
};
