import { List, ListItem, Typography } from '@mui/material';
import React from 'react';
import PageSection from '../../components/Sections/PageSection';
import PageSectionTitle from '../../components/Sections/PageSectionTitle';

type Partner = {
  text: string;
  id: string;
};

interface ItemProps {
  partner: Partner;
}

interface ITPartnerWhoCanBecomeSectionProps {
  partners: Partner[];
}

const Item: React.FC<ItemProps> = ({ partner }) => {
  return (
    <ListItem
      sx={{
        display: 'list-item'
      }}
    >
      <Typography fontWeight={600} component={'span'} fontSize={'1.25rem'} color={'text.primary'}>
        {partner.text}
      </Typography>
    </ListItem>
  );
};

const ITPartnerWhoCanBecomeSection: React.FC<ITPartnerWhoCanBecomeSectionProps> = ({ partners }) => {
  return (
    <PageSection bgcolor='tertiary.main' maxWidth='lg'>
      <PageSectionTitle>Kto może zostać Partnerem ?</PageSectionTitle>
      <List
        sx={{
          listStyleType: 'disc',
          listStylePosition: 'inside',
          color: 'text.secondary',
          fontSize: '1.5rem'
        }}
      >
        {partners.map((partner) => (
          <Item key={partner.id} partner={partner} />
        ))}
      </List>
    </PageSection>
  );
};

export default ITPartnerWhoCanBecomeSection;
