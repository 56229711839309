import { useMediaQuery } from '@mui/material';
import React from 'react';
import theme from '../../../../theme';
import { Benefit } from './Benefit';
import { CooperationBenefitSectionDesktop } from './Desktop';
import CooperationBenefitSectionMobile from './Mobile';

interface CooperationBenefitsSectionProps {
  benefits: Benefit[];
}

const CooperationBenefitsSection: React.FC<CooperationBenefitsSectionProps> = ({ benefits }) => {
  const isSmallerThanLg = useMediaQuery(theme.breakpoints.down('lg'));

  return isSmallerThanLg ? (
    <CooperationBenefitSectionMobile benefits={benefits} />
  ) : (
    <CooperationBenefitSectionDesktop benefits={benefits} />
  );
};

export default CooperationBenefitsSection;
