import { Box, List, ListItem } from '@mui/material';
import React from 'react';
import { Benefit } from '../../Benefit';

interface CurrentBenefitPointsProps {
  currentBenefit?: Benefit;
}
export const CurrentBenefitPoints: React.FC<CurrentBenefitPointsProps> = ({ currentBenefit }) => {
  return (
    <Box flex={1} zIndex={1} color={'white'} fontSize={'1.25rem'}>
      <List
        sx={{
          paddingLeft: 8,
          listStyle: 'disc',
          listStylePosition: 'outside'
        }}
      >
        {currentBenefit?.points.map((point) => (
          <ListItem
            key={point}
            sx={{
              paddingLeft: 0,
              display: 'list-item',
              paddingBottom: 0
            }}
          >
            {point}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
