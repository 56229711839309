import { ListItem } from '@mui/material';
import React from 'react';
import { Benefit } from '../../Benefit';
import PointsList from './MobilePointList';

interface BenefitItemProps {
  benefit: Benefit;
}

const BenefitItem: React.FC<BenefitItemProps> = ({ benefit }) => {
  return (
    <ListItem
      sx={{
        fontSize: '1.25rem',
        display: 'list-item',
        textTransform: 'uppercase',
        fontWeight: 600,
        paddingLeft: 0
      }}
    >
      {benefit.title}
      <PointsList points={benefit.points} />
    </ListItem>
  );
};

export default BenefitItem;
