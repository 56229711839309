import { Box, Container, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Benefit } from '../Benefit';
import { Image } from './Image';
import { CurrentBenefitPoints } from './Benefit/CurrentBenefitPoints';
import DesktopBenefitsList from './Benefit/DesktopBenefitsList';
import { DesktopHeader } from './DesktopHeader';

interface CooperationBenefitSectionDesktopProps {
  benefits: Benefit[];
}

export const CooperationBenefitSectionDesktop: React.FC<CooperationBenefitSectionDesktopProps> = ({ benefits }) => {
  const [currentBenefitId, setCurrentBenefitId] = useState('');

  const handleUpdateBenefit = (id: string) => {
    setCurrentBenefitId(id);
  };

  useEffect(() => {
    if (benefits.length > 0) {
      setCurrentBenefitId(benefits[0].id);
    }
  }, [benefits, benefits.length]);

  return (
    <Box position={'relative'}>
      <Container disableGutters component={'section'} sx={{ paddingY: 6 }}>
        <DesktopHeader />
        <Stack direction={'row'}>
          <DesktopBenefitsList
            activeBenefitId={currentBenefitId}
            benefits={benefits}
            handleUpdateBenefit={handleUpdateBenefit}
          />
          <CurrentBenefitPoints currentBenefit={benefits.find((b) => b.id === currentBenefitId)} />
        </Stack>
        <Image />
      </Container>
    </Box>
  );
};
