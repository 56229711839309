import { Box, Typography } from '@mui/material';
import React from 'react';

export default () => {
  return (
    <Box textAlign={'left'} marginBottom={3}>
      <Typography variant='h2' fontWeight={600} textTransform={'uppercase'} color={'text.primary'} component={'span'}>
        Poznaj&nbsp;
      </Typography>
      <Typography variant='h2' fontWeight={500} textTransform={'uppercase'} color={'text.secondary'} component={'span'}>
        nowy wymiar współpracy partnerskiej, która zapewni twojej firmie:
      </Typography>
    </Box>
  );
};
