import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import PageSectionWithImage from '../../../components/Sections/PageSectionWithImage';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { Box, Typography } from '@mui/material';

export default () => {
  return (
    <PageSectionWithImage
      maxWidth='lg'
      color='white'
      bgimage={
        <StaticImage
          style={{ height: '100%' }}
          layout='fullWidth'
          quality={95}
          src='../../../images/partnerzy_it_hero.jpg'
          alt='Zostań partnerem IT'
        />
      }
    >
      <PageSectionTitle>Zostań Partnerem IT</PageSectionTitle>
      <Box>
        <Typography component={'p'} gutterBottom>
          Wspierasz pracę przychodni np. w zakresie rozliczeń z NFZ, obsługi sprzętowej i chciałbyś zostać partnerem
          Mediporty?
        </Typography>
        <Typography component={'p'} gutterBottom>
          Sprawdź poniższe informacje i wypełni formularz kontaktowy, aby dołączyć do grona firm partnerskich Mediporty.
        </Typography>
      </Box>
    </PageSectionWithImage>
  );
};
