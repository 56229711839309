import { ListItem, Typography } from '@mui/material';
import React from 'react';
import { BenefitArrow } from './BenefitArrow';

export const DesktopBenefitItem: React.FC<{
  text: string;
  isActive: boolean;
  index: number;
  onClick: () => void;
}> = ({ text, isActive, onClick, index }) => (
  <ListItem
    sx={{
      paddingLeft: 0,
      marginBottom: 1.5,
      position: 'relative',
      display: 'list-item',
      cursor: 'pointer',
      textDecorationThickness: 2,
      textDecorationLine: `${isActive ? 'underline' : 'none'}`,
      '&:hover': {
        textDecorationLine: 'underline'
      }
    }}
    onClick={onClick}
  >
    <Typography fontWeight={600} component={'span'} fontSize={'1.3rem'} textTransform={'uppercase'}>
      {index + 1}. {text}
    </Typography>
    {isActive && <BenefitArrow />}
  </ListItem>
);
